// Contact.js
import React from 'react';

function Contact() {

    return (
        < section id='contact'>
            <h1>
                Sariaka
            </h1>
            <h2>
                Numéro: 0674012988
            </h2>
            <br />
            <br />
            <br />
            <h1>
                Riana
            </h1>
            <h2>
                Numéro: 0665123550
            </h2>
            <br />
            <br />
            <br />
            <h1>
                Email: rianasysariaka@gmail.com
            </h1>
        </section >
    );
}
export default Contact;