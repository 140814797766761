// Home.js
import React from 'react';
import About from './About';
import { Button, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


// const Home = () => <h1 className='h_ita'>Home Page</h1>;

function Home() {
    return (
        <>
            <section id='home'>
                <Container>
                    <Row id='spacer1'></Row>
                    <Row>
                        <span>Sariaka & Riana</span>
                    </Row>
                    <Row id='spacer2'></Row>
                    <Row id='info'>
                        <Row>
                            <h3 className='h_ita'>Le 8 juin 2024 à Toulouse</h3>
                        </Row>
                        <Row id='rsvp-btn-row'>
                            <Button as={NavLink} to="/rsvp">RSVP</Button>
                        </Row>
                        <Row>
                            <h5 className='h_ita'>”Et, par-dessus tout cela, revêtez-vous de l’amour qui est le lien par excellence”<br />COLOSSIENS 3:14</h5>
                        </Row>
                    </Row>
                </Container>
            </section>
            <About></About>
        </>
    );
}
export default Home;