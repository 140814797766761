import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import styles from '../style/NavBar.module.css';

const Logo = "LOGO_S&R.svg"

const NavBar = () => {
    return (
        <Navbar bg="light" expand="lg" fixed='top' className={styles.navbar}>
            <Container className={styles.navbarContainer}>
                <Navbar.Brand id='brand' as={NavLink} to="/">
                    <img width="46px" height="auto" className="img-responsive" src={Logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link as={Link} to="/#home" className={styles.navLink}>
                            Accueil
                        </Nav.Link>
                        <Nav.Link as={Link} to="/#about" className={styles.navLink}>
                            Information
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/contact" className={styles.navLink}>
                            Contact
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/rsvp" className={styles.navLink}>
                            RSVP
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;