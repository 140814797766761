import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import RSVP from './components/RSVP';

const routes = [
    { path: '/', component: <Home />, exact: true },
    { path: '/about', component: <About /> },
    { path: '/contact', component: <Contact /> },
    { path: '/rsvp', component: <RSVP /> },
];

export default routes;