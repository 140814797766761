// About.js
import React from 'react';
import chemin from '../img/CARTE_TOULOUSE.svg'
import { Col, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

function About() {
    return (
        < section id='about' >
            <Container>
                <Row>
                    <h2 className='h_nor'>Le 8 juin 2024, accompagnez-nous dans la plus
                        belle journée de notre vie.<br /><br /><br /></h2>
                </Row>
                <Row>
                    <Col>
                        <img className="fit-picture" src={chemin} alt="CARTE_TOULOUSE" />
                    </Col>
                    <Col id='aboutTxt'>
                        <Row className='aboutInfo'>
                            <Col>
                                <h1 className='h_ita hour'>
                                    14h
                                </h1>
                            </Col>
                            <Col xs={9}>
                                <h5 className='h_ita inti'>Cérémonie religieuse au Temple du Salin<br /></h5>
                                <h6 className='h_ita'>4 impasse de la trésorerie, 31000 Toulouse<br /></h6>
                            </Col>
                        </Row>
                        <Row className='aboutInfo'>
                            <Col>
                                <h1 className='h_ita hour'>
                                    18h
                                </h1>
                            </Col>
                            <Col xs={9}>
                                <h5 className='h_ita inti'>Réception à l’Orangerie des demoiselles<br /></h5>
                                <h6 className='h_ita'>20 Rte de Plaisance, 31270 Frouzins<br /></h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section >
    );
}



export default About;