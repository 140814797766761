import React, { useState } from "react";
import { FloatingLabel, Form, Button, Container, Row, Col } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import "../style/RSVP.css";

function Formulaire() {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [guestCount, setGuestCount] = useState(0);
  const [guestDetails, setGuestDetails] = useState([]);
  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleGuestCountChange = (e) => {
    const count = parseInt(e.target.value);
    setGuestCount(count);
    setGuestDetails(
      Array.from({ length: count }, () => ({ firstName: "", lastName: "" }))
    );
  };

  const handleGuestDetailsChange = (index, key, e) => {
    const newGuestDetails = [...guestDetails];
    newGuestDetails[index][key] = e.target.value;
    setGuestDetails(newGuestDetails);
  };

  const info = {
    email,
    phoneNumber,
    guestCount,
    guestDetails,
  }

  const header = {
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*"
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with:", info);
    console.log(JSON.stringify(info, null, 2))
    axios.post("https://2fni0mansb.execute-api.us-east-1.amazonaws.com/prd/guests", info, header)
      .then((response) => {
        console.log(response);
      });
    navigate("/")
  };

  return (
    <section id="RSVPcontainer">
      <Container>
        <Row><br /><br /></Row>
        <Form className="RSVP-form" onSubmit={handleSubmit}>
          <h1 className="text-center">RSVP</h1>
          <Row>
            <Col>
              <Form.Group className="form-group" controlId="email">
                {/* <Form.Label>Email</Form.Label> */}
                <FloatingLabel
                  controlId="Email"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group" controlId="phoneNumber">
                <FloatingLabel
                  controlId="tel"
                  label="Télephone"
                  className="mb-3"
                >
                  <Form.Control
                    type="tel"
                    placeholder="Télephone"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                </FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <Col>
            <Form.Group className="form-group" controlId="guestCount">
              <FloatingLabel
                controlId="number"
                label="Nombre d'invités"
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder="nombre d'invités"
                  value={guestCount}
                  onChange={handleGuestCountChange}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>

          {guestDetails.map((guest, index) => (
            <div key={index}>
              invité n°{index + 1}:
              <Form.Group
                className="guest-details"
                controlId={`guestDetails-${index}`}
              >
                <Row>
                  <Col>
                    <FloatingLabel
                      controlId="text"
                      label="Nom"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={`Nom`}
                        value={guest.lastName}
                        onChange={(e) => handleGuestDetailsChange(index, "lastName", e)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel
                      controlId="text"
                      label="Prénom"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder={`Prénom`}
                        value={guest.firstName}
                        onChange={(e) =>
                          handleGuestDetailsChange(index, "firstName", e)
                        }
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <FloatingLabel
                  controlId="text"
                  label="Commentaire"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    as="textarea"
                    placeholder={`commentaire`}
                    value={guest.comment}
                    onChange={(e) =>
                      handleGuestDetailsChange(index, "comment", e)
                    }
                  />
                </FloatingLabel>
              </Form.Group>
            </div>
          ))}

          <Button type="submit">Valider</Button>
        </Form>
      </Container>
    </section>
  );
}

export default Formulaire;
